import { Form, Input, Checkbox, Button, Modal, Popover } from 'antd';
import React, { useState, useRef } from 'react';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import SHA256 from 'crypto-js/sha256';
import { enc } from 'crypto-js';
import { stringReducer } from "../actions";
import { Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SmileOutlined, LockOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons';
import logo from "../img/text-logo1.png";
import global from "../img/common/global.png";
import checkOff from "../img/common/checkOff.png"
import checkOn from "../img/common/checkOn.png"
import { ArrowLeftOutlined, } from '@ant-design/icons';

const SignUp = (props) => {
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);


    const [idCheckStep, setIdCheckStep] = useState(1); //1-입력, 2-중복확인완료, 3-번호입력, 4-인증완료

    // const [emailDuplCheck, setEmailDuplCheck] = useState(false);
    // const [certiVerified, setCertiVerified] = useState(false);
    // const [phoneEditable, setPhoneEditable] = useState(true);

    const [timer, setTimer] = useState(180);
    const timerRef = useRef(180);
    const [onTimer, setOnTimer] = useState(false);
    
    const [visible, setVisible] = useState(false);
    const [recommendCode, setRecommendCode] = useState(props.match ? props.match.params.recommendCode : '');
    const [resendEmailVisible, setResendEmailVisible] = useState(false);
    const [resendEmailAddress, setResendEmailAddress] = useState('');
    const [resendEmailError, setResendEmailError] = useState('');
    const [email, setEmail] = useState();
    const [globalOpen, setGlobalOpen] = useState(false);
    const [checkAgree, setCheckAgree] = useState(false);

    const certiCodeToken = useRef('');

    const onPressEmailDuplCheck = () => {
        if (idCheckStep != 1) return;       
        httpGet(httpUrl.userCheckEmail, [email]).then((result) => {
            console.log("## onPressEmailDuplCheck result: " + JSON.stringify(result));
            result = result.data;
            if (result == "SUCCESS") {
                formRef.current.setFields([{
                    name: 'email',
                    value: email,
                    errors: []
                }]);
                setIdCheckStep(4);
            } else {
                console.log("## onPressEmailDuplCheck error");
                let message = string.emailDuplCheckError;
                if (result == 'INVALID_EMAIL') message = string.emailDuplCheckErrorInvalidEmail;
                if (result == 'ALREADY_EXIST') message = string.emailDuplCheckErrorEmailDupl;
                formRef.current.setFields([{
                    name: 'email',
                    value: email,
                    errors: [message]
                }]);
            }
        }).catch(e => {
            console.log("## userPhoneauthCheck error: " + e)
        });

    }

    const onPressSendCertiCode = () => {
        if (idCheckStep != 2) return;
        if (onTimer) return;
        const form = formRef.current;
        const formPhone = form.getFieldValue("email").replace(/[^0-9]+/g,'');
        form.setFields([{ name: 'email', value: formPhone }]);

        if (formPhone == undefined || formPhone == '' || formPhone.length < 9) {
            form.setFields([{
                name: 'email',
                errors: [string.signUpRuleMsg08]
            }]);
            return;
        }
        httpPost(httpUrl.userPhoneauthGenerate, [], { phone: formPhone }).then((result) => {
            console.log("## userPhoneauthGenerate result: " + JSON.stringify(result));
            result = result.data.token;
            certiCodeToken.current = result;
            if (result && result != '') {
                setIdCheckStep(3);
                setOnTimer(true);
                let interval = setInterval(
                    () => {
                        console.log('timer interval'+timerRef.current)
                        if (timerRef.current == 0) {
                            clearInterval(interval);
                            setOnTimer(false);
                            setTimer(180);
                            setIdCheckStep(2);
                            return;
                        }
                        setTimer(timerRef.current - 1);
                        timerRef.current = timerRef.current - 1;
                    },
                    1000
                );
            } else {
                form.setFields([{
                    name: 'email',
                    errors: [string.certiCodeSendFail]
                }]);
                console.log("## userPhoneauthGenerate server error");
            }
        }).catch(e => {
            console.log("## userPhoneauthGenerate error: " + e)
        });
    }

    const onPressCheckCertiCode = () => {
        if (idCheckStep != 3) return;
        if (!onTimer) return;

        const form = formRef.current;
        const formCertiCode = form.getFieldValue("certiCode");

        if (formCertiCode == undefined || formCertiCode == '') {
            form.setFields([{
                name: 'certiCode',
                errors: [string.certiCodeFail]
            }]);
            return;
        };

        httpGet(httpUrl.userPhoneauthCheck, [certiCodeToken.current, formCertiCode], {}).then((result) => {
            console.log("## userPhoneauthCheck result: " + JSON.stringify(result));
            result = result.data;

            if (result.responseCode == "SUCCESS") {
                setIdCheckStep(4)
            } else {
                console.log("## userPhoneauthCheck server error");
                let message = string.certiCodeWrong;
                if (result.responseCode == 'EXPIRED') message = string.certiCodeExpired;
                form.setFields([{
                    name: 'certiCode',
                    errors: [message]
                }]);
            }
        }).catch(e => {
            console.log("## userPhoneauthCheck error: " + e)
        });
    };

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    };

    const handleSubmit = (values) => {
        const form = formRef.current;

        let password = form.getFieldValue("password") + form.getFieldValue("email").trim().toLowerCase();
        let secPassword = form.getFieldValue("securityPassword") + form.getFieldValue("email").trim().toLowerCase();

        // let passwordHash = crypto
        // .createHash('sha256')
        // .update(password, 'utf8')
        // .digest()
        // .toString('hex');
        
        // let secPasswordHash = crypto
        // .createHash('sha256')
        // .update(secPassword, 'utf8')
        // .digest()
        // .toString('hex');
        let passwordHash = 
        SHA256(password)
            // .createHash('sha256')
            // .update(password, 'utf8')
            // .digest()
            .toString(enc.Hex);
        let secPasswordHash = 
        SHA256(secPassword)
            // .createHash('sha256')
            // .update(password, 'utf8')
            // .digest()
            .toString(enc.Hex);

        httpPost(httpUrl.signUp, [language], {
            deviceId: 'web',
            id: form.getFieldValue("email").trim().toLowerCase(),
            mnemonic: 'web',
            password: passwordHash,
            name: form.getFieldValue("name"),
            phone: form.getFieldValue("email"),
            recommender: recommendCode,
            securityPassword: secPasswordHash,
            emailVerification: 0
        }).then((result)=>{
            console.log("## signUp result: " + JSON.stringify(result));
            result = result.data;      
            
            if (result.result){
                navigate('/signupok');
            } else {
                let message = string.signUpErrorMessage;
                if(result.reason == 'DUPLICATE') message = string.signUpErrorIdDuplication;
                else if(result.reason == 'WRONG_EMAIL_TYPE') message = string.signUpErrorInvalidEmailType;
                else if(result.reason == 'MAX_PHONE') message = string.signUpErrorMaxPhone;
                
                Modal.info({
                    title: string.signUpFailTitle,
                    content: (
                    <div className="signup-result">
                        {message}
                    </div>
                    ),
                    onOk() {},
                });
            }
        
        }).catch(e=>{
        console.log ("## signUp error: " + e)
        });
    }

    const agreementModal = () => {
        Modal.info({
          width: 500,
          title: string.policyTitle,
          content: (
            <div className="agreement-content">
                {string.policyContent.map((value, index) => {
                    let result = [];
                    if (value.title)
                        result.push(
                        <div key={'title_' + index} className="agreement-content-title">
                            {value.title}
                        </div>
                        );
                    if (value.content)
                        result.push(
                        <div key={'content_' + index} className="agreement-content-body">
                            {value.content}
                        </div>
                        );
                    return result;
                })}
            </div>
          ),
          onOk() {},
        });
      }

    const resendEmail = () => {        
        if (resendEmailAddress == null) {
            setResendEmailError(string.emailRule03)
            return;
        }
        setResendEmailAddress(resendEmailAddress.trim());
        if (resendEmailAddress == '') {
            setResendEmailError(string.emailRule03)
            return;
        }
        var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        if (!regExp.test(resendEmailAddress)) {
            setResendEmailError(string.emailRule03)
            return;
        }
        httpGet(httpUrl.userResendEmailSignup, [resendEmailAddress]).then((result)=>{
            result = result.data;
            if (result == "SUCCESS"){
                setResendEmailVisible(false);
                Modal.info({
                    content: (
                    <div className="signup-result">
                        {string.emailResent}
                    </div>
                    ),
                    onOk() {},
                });
            } else {
                let message = string.emailResendFailed;
                if (result == "INVALID_EMAIL") message = string.invalidEmail;
                if (result == "ALREADY_VERIFIED") message = string.emailAlreadyVerified;
                setResendEmailVisible(false);
                Modal.info({
                    content: (
                    <div className="signup-result">
                         {message}
                    </div>
                    ),
                    onOk() {},
                });              
            }
      
        }).catch(e=>{
            console.log ("## resendEmail error: " + e);
        });
    }

    const changeLan = (lan) =>{ dispatch(stringReducer(lan)) };
    const onCheckAgree = () => {
        let check = checkAgree ? false : true;
        setCheckAgree(check)
        if (check) agreementModal();
    }
    return (
        <div id="signup">
            <div className='header'>
            <div className='backIcon1' onClick={()=>navigate(-1)}>
                <ArrowLeftOutlined /> Back
            </div>
                <Popover                
                    content={
                        <div className='pophover'>
                            <div onClick={()=>changeLan('ko')} className={language == 'ko' ? "lan active": "lan"}>Korean</div>
                            <div onClick={()=>changeLan('en')} className={language == 'en' ? "lan active": "lan"}>English</div>
                            <div onClick={()=>changeLan('ja')} className={language == 'ja' ? "lan active": "lan"}>Japanese</div>
                        </div>                    
                    }
                    trigger={"click"}
                    open={globalOpen}
                    onOpenChange={()=>setGlobalOpen(!globalOpen)}>
                    <img src={global} className='globalImg'/>
                </Popover>
            </div>
            <div className='welcome'>
                <img src={logo} className='logoImg'/>
                <p>{string.welcome}</p>
            </div>
            <div className='container'>              
                <Form onFinish={handleSubmit} className="form" ref={formRef}>   
                    <p className='title'>{string.idEmail}</p>
                    <Form.Item name="email"
                        rules={[({ getFieldValue }) => ({ validator(rule, value) {
                                if (!value || value === '') return Promise.reject(string.signUpRuleMsg09);
                                else if (idCheckStep == 1) return Promise.reject(string.needEmailDuplCheck);
                                else if (idCheckStep == 2) return Promise.reject(string.certiCodeNotVerified);
                                else if (idCheckStep == 3) return Promise.reject(string.certiCodeNotVerified);
                                    else return Promise.resolve();
                                },
                            }),                                   
                        ]}
                    >
                        
                        <div className='formBox' style={{backgroundColor: idCheckStep >= 2 ? '#e1e1e1' : '#fff'}}>                  
                            <input className="login-input"
                                autoComplete='false'
                                disabled={idCheckStep > 1}
                                placeholder={string.idEmail}            
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{width: '150px'}}
                            />    
                            {idCheckStep == 2 && (
                                <div className={idCheckStep == 4 ? "overlap-button ok":"overlap-button"} onClick={onPressSendCertiCode}>
                                    {idCheckStep == 4 ? "인증완료" : "인증번호전송"}
                                </div>  
                            )}
                            {idCheckStep == 1 && (
                                <div className={idCheckStep == 1 ? "overlap-button":"overlap-button ok"} onClick={onPressEmailDuplCheck}>
                                    {idCheckStep == 1 ? string.dupCheck : string.completed}
                                </div>  
                            )}
                            {idCheckStep >= 2 && (
                                <div className={"overlap-button"} onClick={()=>{
                                    setIdCheckStep(1);
                                }}>
                                    리셋
                                </div>  
                            )}       
                        </div>  
                    </Form.Item>
                    {/* {idCheckStep >= 3 && (
                        <>
                            <p className='title'>핸드폰 인증번호</p>
                            <Form.Item name="certiCode"
                                rules={[({ getFieldValue }) => ({ validator(rule, value) {
                                        if (!value || value === '') return Promise.reject('인증번호를 입력해주세요');
                                            else return Promise.resolve();
                                        },
                                    }),                                   
                                ]}
                            >
                                
                                <div className='formBox' style={{backgroundColor: idCheckStep >= 4 ? '#e1e1e1' : '#fff'}}>                  
                                    <input className="login-input"
                                        autoComplete='false'
                                        placeholder={'인증번호'}            
                                    />    
                                    {idCheckStep == 3 && (
                                    <div className={"overlap-button text"}>
                                        {timer}초
                                    </div>  
                                    )}
                                    <div className={idCheckStep == 4 ? "overlap-button ok":"overlap-button"} onClick={onPressCheckCertiCode}>
                                    {idCheckStep == 4 ? "인증완료" : "확인"}
                                    </div>  
                                </div>  
                            </Form.Item>
                        </>
                    )} */}
                    <p className='title'>{string.passwordRule}</p>
                    <Form.Item  name="password"
                        rules={[
                            { required: true, message: string.signUpRuleMsg10},
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    let strongRegex = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$' );
                                    if (!strongRegex.test(value)) return Promise.reject(string.signUpRuleMsg07);
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <div className='formBox'>                  
                            <input className="login-input"
                                autoComplete='false'
                                type='password'
                                placeholder={string.passwordRule}     
                            />              
                        </div>  
                    </Form.Item>
                    <p className='title'>{string.passwordConfirm}</p>
                    <Form.Item name="passwordConfirm"
                        rules={[
                            { required: true, message: string.signUpRuleMsg11},
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(string.signUpRuleMsg05);
                                },
                            }),
                        ]}
                    >
                        <div className='formBox'>                  
                            <input className="login-input"
                                autoComplete='false'
                                type='password'
                                placeholder={string.passwordConfirm}     
                            />              
                        </div>  
                    </Form.Item>
                    <p className='title'>{string.name}</p>
                    <Form.Item name="name" rules={[{ required: true, message: string.signUpRuleMsg12}]}>
                        <div className='formBox'>                  
                            <input className="login-input"
                                autoComplete='false'
                                placeholder={string.name}     
                            />              
                        </div>  
                    </Form.Item>
                    {/* <p className='title'>{string.phone}</p> */}
                    {/* <Form.Item name="phone">
                        <div className='formBox'>                  
                            <input className="login-input"
                                autoComplete='false'
                                disabled={!phoneEditable}
                                placeholder={string.inputTel}     
                            />              
                        </div>  
                    </Form.Item> */}
                    <p className='title'>{string.securityPw}</p>
                    <Form.Item name="securityPassword"
                        rules={[
                            { required: true, message: string.signUpRuleMsg12},            
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                if (!value || value.length != 4) return Promise.reject(string.signUpRuleMsg14);
                                    return Promise.resolve();
                                },
                            }),                    
                                                        
                        ]}>
                        <div className='formBox'>                  
                            <input className="login-input"
                                autoComplete='false'
                                type="number"
                                placeholder={string.inputSecurity}     
                                maxLength={4} 
                                onInput={maxLengthCheck} 
                            />              
                        </div>  
                    </Form.Item>
                    <p className='title'>{string.inputSecurityConfirm}</p>
                    <Form.Item name="securityPasswordConfirm"
                        rules={[
                            { required: true, message: string.signUpRuleMsg14},            
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('securityPassword') === value) { return Promise.resolve()}
                                        return Promise.reject(string.signUpRuleMsg15);
                                },
                            }),                                                 
                        ]}>
                        <div className='formBox'>                  
                            <input className="login-input"
                                autoComplete='false'
                                type="number"
                                placeholder={string.inputSecurityConfirm}     
                                maxLength={4} 
                                onInput={maxLengthCheck} 
                            />              
                        </div>  
                    </Form.Item> 
                    <div onClick={onCheckAgree} className='option'>
                        <img src={checkAgree ? checkOn : checkOff} style={{width: 14,marginRight: 6}} />
                        <p>{string.policyTitle2}</p>
                    </div>
                    <Form.Item className='btnbox'>
                        <button className='loginBtn' type="primary" htmlType="submit" >{string.signUp}</button>
                    </Form.Item>            
                    {/* <div style={{marginTop: 16, textAlign:'center'}}>
                        {string.emailResendGuide1}<br/>
                        {string.emailResendGuide2}
                    </div>
                    <div className='btnbox'>              
                        <button className="resend-btn-ok" onClick={()=>setResendEmailVisible(true)}>{string.emailResend}</button>                 
                    </div> */}
                </Form>          
            </div>
            <Modal
                title={string.emailResend}
                open={resendEmailVisible}
                onOk={resendEmail}
                onCancel={()=> setResendEmailVisible(false)}
                okText={string.ok}
                cancelText={string.cancel}>
                <div style={{paddingBottom: '10px'}}>
                    {string.inputJoinEmail}
                </div>
                <div>
                    <Input className="input22" type="email"
                        placeholder={string.idEmail}
                        onChange={(e)=>setResendEmailAddress(e.target.value)}
                        value={resendEmailAddress}
                    />
                </div>
                <div style={{paddingBottom: '5px', color: 'red'}}>
                    {resendEmailError}
                </div>
            </Modal>
        </div>
    );
}


export default SignUp;